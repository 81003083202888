
.jumbotron {
    background-color: #2f3847; 
    color: white;
}

.container .jumbotron {
    color: black;
}

.position-bottom {
    position: absolute;
    color: white;
    width: 100%;
}

.Padding-Top {
    margin-top: 80px;
    background-color: #2f3847;
    text-align: center;
    color: white;
    padding: 20px;
    border-radius: 2px;
}

.Black-Text {
    padding-top: 15%;
    color: black;
}

.Email-Form {
    text-align: left;
}


/* Below flips the color scheme of jumbotrons*/
.flip-white {
    background-color: white;
    color: black;
}

.flip-orange {
    background-color: #e7a117;
    color: white;
    text-align: left;
    vertical-align: middle;
    /*font-family: 'Courier New', Courier, monospace;*/
}

.Col .Button {
    background-color: #2f3847;
}

.center-text {
    text-align: left;
    vertical-align: middle;
}

.jumbotron .card {
    color: black;
}

.Copyright {
    text-align: center;
    vertical-align: center;
    color: black;
}

.Left-Aligned {
    text-align: left;
    font-size: 20px;
}